import React from 'react';
import Section from '../components/Section';
import Metadata from '../components/Metadata';

function bcba() {
  return (
    <>
      <Metadata title='BCBA' />
      <Section heading='BCBA/BCaBA'>
        <p>
          BCBA (Board Certified Behavior Analyst): Voraussetzung ist ein Master
          Abschluss in einem anerkannten Studiengang aus dem Gesundheits-,
          Sozial- oder Bildungswesen. Um die Zertifizierung zu erhalten, muss
          der Kandidat entsprechende Universitätskurse, 1.500 supervidierte
          Arbeitsstunden sowie ein mehrstündiges schriftliches Examen
          absolvieren. Der BCBA muss im zweijahresrhythmus Fortbildungspunkte
          nachweisen, um seine Zertifizierung zu behalten.
        </p>
        <p>
          BCaBA (Board Certified assistant Behavior Analyst): Voraussetzung ist
          ein Bachelor Abschluss in einem anerkannten Studiengang aus dem
          Gesundheits-, Sozial- oder Bildungswesen. Um die Zertifizierung zu
          erhalten, muss der Bewerber entsprechende Universitätskurse, 1,000
          supervidierte Arbeitsstunden sowie ein mehrstündiges schriftliches
          Examen absolvieren. Um seine Zertifizierung zu behalten muss der BCaBA
          regelmäßige Supervision durch einen BCBA/BCBA-D erhalten und
          Fortbildungspunkte nachweisen.
        </p>
        <p>
          Weitere Informationen sowie weitere Abschlüsse finden Sie unter{' '}
          <a href='https://www.bacb.com' target='_blank' rel='noreferrer'>
            www.bacb.com
          </a>
        </p>
      </Section>
    </>
  );
}

export default bcba;
